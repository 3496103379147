import React, { useState } from 'react';
import { FaFacebookF, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import useIsMobile from '../component/IsMobile';

const Drop = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedSubOption, setSelectedSubOption] = useState('');
    // const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    const handleChange = (event) => {
        setSelectedOption(event.target.value);
        setSelectedSubOption(''); // Reset sub option when main option changes
    };

    const handleSubChange = (event) => {
        setSelectedSubOption(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (selectedSubOption) {
            // Save the data in local storage
            localStorage.setItem('selectedOption', selectedOption);
            localStorage.setItem('selectedSubOption', selectedSubOption);
            
            // Navigate to the desired route
            navigate(`/carservice?type=${selectedSubOption}`);
        }
    };

    const getSubOptions = () => {
        switch (selectedOption) {
            case 'carWash':
                return (
                    <>
                        <option value="">Select a car type</option>
                        <option value="hatchback">Hatchback</option>
                        <option value="suv">SUV</option>
                        <option value="sedan">Sedan</option>
                    </>
                );
            case 'twoWheelerWash':
                return (
                    <>
                        <option value="">Select a 2 Wheeler type</option>
                        <option value="bike">Bike</option>
                        <option value="scooter">Scooter</option>
                    </>
                );
            default:
                return <option value="">Select a type</option>;
        }
    };

    return (
        <div className='backgroundChange'>
            <div className='mb-5'>
                <h3 className='text-center mystic-content heading' style={{borderTop:"5px solid #0FA4AF"}}><strong>Book Us</strong></h3>
            </div>
            <form onSubmit={handleSubmit} style={{display:"flex", justifyContent:"center", marginBottom:"2%"}}>
                <div style={isMobile?{width:"40%" } :{width:"25%" }}>
                    <label htmlFor="options"></label>
                    <select style={{width:"100%", textAlign:"center", padding:"5px"}} id="options" value={selectedOption} onChange={handleChange}>
                        <option value="">Select Vehicle</option>
                        <option value="carWash">Car Wash</option>
                        <option value="twoWheelerWash">2-Wheeler Wash</option>
                    </select>
                </div>
                
                {selectedOption && (
                    <div style={isMobile?{width:"40%", marginRight:"2%", marginLeft:"2%"}:{width:"25%", marginRight:"2%", marginLeft:"2%"}}>
                        <label htmlFor="subOptions"></label>
                        <select style={{width:"100%", textAlign:"center", padding:"5px"}} id="subOptions" value={selectedSubOption} onChange={handleSubChange}>
                            {getSubOptions()}
                        </select>
                    </div>
                )}

                {selectedSubOption && <button className='button1' style={{textAlign:"center", padding:"7px", borderRadius:"10px"}} type="submit">Book Now</button>}
            </form>
        <div style={{textAlign:"center"}}>
        <h2 className='text-center mystic-content heading' >OR</h2>
            <h3 className='text-center mystic-content heading' ><strong>Contact for Direct Booking</strong></h3>
                    <h4 style={{color:"aliceblue"}}><strong >Email: </strong>kleanonveel@gmail.com</h4>
                    <h4 style={{color:"aliceblue"}}><strong>Phone/Whatsapp:</strong> +91 9911311361</h4>
                    <div className="social-icons">
                        <a href="https://www.facebook.com"><FaFacebookF /></a>
                        <a href="https://www.twitter.com"><FaTwitter /></a>
                        <a href="https://www.instagram.com"><FaInstagram /></a>
                        <a href="https://www.linkedin.com"><FaLinkedin /></a>
                    </div>
                    </div>
        </div>
    );
};

export default Drop;
