import React from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';

const OrderSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orderId } = location.state || {};

  const handleReturn = () => {
    navigate('/');
  };

  return (
    <SuccessCard>
      <Card>
        <h2>Order Successful!</h2>
        <p>Thank you for your order. Your order ID is:</p>
        <OrderId>{orderId}</OrderId>
        <ReturnButton onClick={handleReturn}>Return to Home</ReturnButton>
      </Card>
    </SuccessCard>
  );
};

const SuccessCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
`;

const Card = styled.div`
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const OrderId = styled.p`
  font-size: 24px;
  font-weight: bold;
  margin: 20px 0;
`;

const ReturnButton = styled.button`
  background-color: #008CBA;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #007BAC;
  }
`;

export default OrderSuccess;
