import React from 'react';

import ImageCarousel from './ImageCarousel';
import MyTimer from './timer';
import ContactUs from './ContactUs';

import Drop from './Drop';
// import Chatbot from './Chatbot';
import ServiceList from './ServiceList';
import LoveUs from './LoveUs';

const Home = () => {

    // const handleChange = (event) => {
    //     setSelectedOption(event.target.value);
    // };
  return (
    <div style={{backgroundColor:"#f9f9f9"}}>  
       <ImageCarousel/> 
      <MyTimer />
      <LoveUs/>
      <section id="book"> <Drop/></section>        
        <section id="ServiceList"><ServiceList/></section>
        <section id="contact"> <ContactUs></ContactUs></section>
    </div>
  );
};

export default Home;