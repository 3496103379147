import React, { useState } from 'react';
import './ContactUs.css'; // Import the CSS file for styling
import axios from 'axios';

const ConnectForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        address:'',
        comment: ''
    });

    const [responseMessage, setResponseMessage] = useState(''); // State to handle response message
    const [isSubmitting, setIsSubmitting] = useState(false); // State to handle submission status

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        try {
            setIsSubmitting(true); // Optional: Set a loading state if you have one
            const res = await axios.post("https://kleanonveel.com/api/contactus.php", formData);
            console.log("Response received:", res);
            setResponseMessage("Your query has been successfully submitted!"); // Success message
        } catch (error) {
            console.error("Error occurred:", error);
            setResponseMessage("There was an error submitting your query. Please try again."); // Error message
        } finally {
            setIsSubmitting(false); // Reset submission state
            setFormData({
                name: '',
                phone: '',
                email: '',
                address:'',
                comment: ''
            });
        }
    };

    return (
        <section>
            <div className="form-container">
                <div className="form-header">
                    <h3>MAKE A QUERY</h3>
                    <p>Get in Touch with Us</p>
                </div>
                <form className="contact-form" onSubmit={handleSubmit}>
                    <div>
                        <div className="form-group">
                            <label htmlFor="name"></label>
                            <input 
                                placeholder='Name'
                                type="text" 
                                id="name" 
                                name="name" 
                                value={formData.name} 
                                onChange={handleChange} 
                                required 
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone"></label>
                            <input 
                                placeholder='Phone Number'
                                type="tel" 
                                id="phone" 
                                name="phone" 
                                value={formData.phone} 
                                onChange={handleChange} 
                                required 
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email"></label>
                            <input 
                                placeholder='Email'
                                type="email" 
                                id="email" 
                                name="email" 
                                value={formData.email} 
                                onChange={handleChange} 
                                required 
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="address"></label>
                            <input 
                                placeholder='Address'
                                type="text" 
                                id="Address" 
                                name="address" 
                                value={formData.address} 
                                onChange={handleChange} 
                                required 
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="comment"></label>
                        <textarea 
                            placeholder='Message'
                            id="comment" 
                            name="comment" 
                            value={formData.comment} 
                            onChange={handleChange} 
                            rows="3" 
                            required 
                        ></textarea>
                    </div>
                    <button type="submit" className="form-button" disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                    {responseMessage && <p className="response-message">{responseMessage}</p>} {/* Display response message */}
                </form>
            </div>
        </section>
    );
};

export default ConnectForm;
