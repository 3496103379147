import React, { useEffect, useRef } from 'react';
import './AboutUs.css';
import useIsMobile from '../component/IsMobile';

const AboutUs = () => {
  const boxRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  useEffect(() => {
    const boxElement = boxRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            boxElement.classList.add('animate');
          } else {
            boxElement.classList.remove('animate');
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold as necessary
    );

    if (boxElement) {
      observer.observe(boxElement);
    }

    return () => {
      if (boxElement) {
        observer.unobserve(boxElement);
      }
    };
  }, []);
  const teamMembers = [
    {
      name: 'ROHIT PANJWANI',
      position: 'Founder & CEO',
      image: './images/our.jpg',
      description: 'Rohit ensures that our operations run smoothly, coordinating with our team to deliver top-notch services to our clients.'
      
    },
    {
      name: 'SYED HAMZA ZAIDI',
      position: 'CTO',
      image: './images/our.jpg',
      description: 'Hamza is the tech visionary behind KleanonVeel, dedicated to revolutionizing car maintenance with a passion for eco-friendly solutions.'
    },
    {
      name: 'ALEX JOHNSON',
      position: 'Lead Technician',
      image: './images/our.jpg',
      description: 'Alex leads our skilled technicians, bringing expertise and precision to every car wash and maintenance service.'
    },
    {
      name: 'SHABANA KHAN',
      position: 'Customer Care Executive',
      image: './images/our.jpg',
      description: 'Alex leads our skilled technicians, bringing expertise and precision to every car wash and maintenance service.'
    },
    
  ];
  const isMobile = useIsMobile();
  return (
    <div className="about-us-container1">
           <div className="video-background">
  {isMobile ? (
    <video
      src="./video/videoplayback.mp4"
      autoPlay
      muted
      loop
      playsInline
      className="background-video"
      title="Background Shorts Video"
    />
  ) : (
    <iframe
      src="https://www.youtube.com/embed/IoUEue8-kpo?autoplay=1&mute=1&loop=1&playlist=IoUEue8-kpo"
      frameBorder="0"
      allow="autoplay; fullscreen"
      allowFullScreen
      title="Background Video"
      className="background-video"
    />
  )}
</div>

      <section className="about-us-content">
        <div className="company-overview">
          {/* <h2 style={{fontWeight:"bolder"}}>About Klean on Veel</h2> */}
          
    <div className='containerA'>
      {/* <div className='box1 gradient-border'>   
      </div>
      <img src="./images/our.jpg" alt=""></img> */}
      {isMobile?<div className=''>
        <h1 className='MPara'> Dive into KleanonVeel' Universe!</h1>
    
        </div>
        :<div className='para'>
        <h2 className='team-title'> Dive into KleanonVeel' Universe!</h2>
      <p >
      At KleanonVeel, we believe in the power of moments – those magical instances that linger in our memories long after they’ve passed. Our journey began with a simple yet profound mission: to transform ordinary gatherings into extraordinary experiences.
          </p>
        </div>}
      
        </div>
        
        </div> 
       
        {/* <div className="mission-vision-values">
          <h3>Our Mission</h3>
          <p>
            Our mission is to revolutionize car maintenance by offering the most convenient and eco-friendly car washing solutions available. We strive to exceed customer expectations with our reliable and professional services.
          </p>
          <h3>Our Vision</h3>
          <p>
            Our vision is to become the leading provider of doorstep car washing services in the region, known for our commitment to excellence and sustainability. We aim to set new standards in convenience and quality for vehicle care.
          </p>
          <h3>Our Values</h3>
          <ul>
            <li>Customer Satisfaction: We are dedicated to delivering exceptional service and ensuring customer satisfaction.</li>
            <li>Environmental Responsibility: We use eco-friendly products and methods to reduce our environmental impact.</li>
            <li>Integrity: We conduct our business with honesty and transparency, building trust with our clients.</li>
            <li>Innovation: We continuously seek to improve our services and adopt new technologies to stay ahead in the industry.</li>
          </ul>
        </div> */}
      <div className={isMobile?"MobileBackgroundChange":""}>
      <section className="">        
          <div style={{backgroundColor:"#0FA4AF"}}>
            <h3 className='team-title' style={{fontFamily:"Georgia, serif"}}>OUR PHILOSOPHY</h3>
            <div className='container2'>
      <div ref={boxRef} className='box2'>   
      </div>
      <img src="./images/professional.jpg" alt=""></img>
      <div className='para2'>
            <p>
            At KleanonVeel, we believe in the power of connection. We strive to create experiences that not only wow our clients but also foster meaningful connections among guests. We’re committed to sustainability, inclusivity, and making a positive impact in everything we do.
            </p>
          </div>
          </div>
          </div>
         
          
          <div style={{backgroundColor:"#0FA4AF"}}>
            <h3 className='team-title'>OUR STORY</h3>
            <div className='container3'>
      <div className='box3'>   
      </div>
      <img src="./images/professional.jpg" alt=""></img>
      <div className='para3'>
            <p >
            Our journey began 2023 with a simple yet powerful idea: to create events that captivate hearts and leave lasting impressions. From humble beginnings, we’ve grown into a full-service event management company, serving clients with dedication and innovation.
            </p>
          </div>
          </div>
          </div>
          <div style={{backgroundColor:"#0FA4AF"}}>
            <h3 className='team-title'>OUR APPROACH</h3>
            <div className='container2'>
      <div className='box2'>   
      </div>
      <img src="./images/professional.jpg" alt=""></img>
      <div className='para2'>
            <p>
            At KleanonVeel, we believe that every event is an opportunity to tell a unique story. We approach each project with a blend of creativity, professionalism, and attention to detail, ensuring that every aspect is meticulously planned and flawlessly executed.
            </p>
          </div>
          </div>
          
          </div>
        
      </section>
    </div>
      <h2 className='team-title'>Our Amazing Team</h2>
      <div className="team-container">
  <img src='./images/team.jpeg' className="background-image" alt="Team Background" />
  {teamMembers.map((member, index) => (
    <div key={index} className="team-member">
      <img src={member.image} alt={member.name} className="team-member-image" />
      <h3 className="team-member-name">{member.name}</h3>
      <p className="team-member-position">{member.position}</p>
      <p className="team-member-description">{member.description}</p>
    </div>
  ))}


    </div>
    
      </section>
    </div>
  );
};

export default AboutUs;
