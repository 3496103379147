import React, { useState, useEffect } from 'react';
import './LoveUs.css';

const reasons = [
    { title: 'Eco-Friendly', description: 'We use waterless technology to save water and reduce environmental impact.', image: '/images/1.jpg' },
    { title: 'Convenient', description: 'Our doorstep service means you can get your car washed without leaving your home or office.', image: '/images/convenient.jpg' },
    { title: 'Professional', description: 'Our trained professionals use the best products and techniques to ensure your car looks its best.', image: '/images/professional.jpg' },
    { title: 'Affordable', description: 'We offer competitive pricing without compromising on quality.', image: '/images/saving.jpg' },
];

const LoveUs = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [itemsToShow, setItemsToShow] = useState(3); // Default to 3 items for larger screens

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setItemsToShow(1); // Show 1 item on small screens
            } else if (window.innerWidth <= 1024) {
                setItemsToShow(2); // Show 2 items on medium screens
            } else {
                setItemsToShow(3); // Show 3 items on large screens
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Call once to set the initial value

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const goToPrev = () => {
        setCurrentIndex(currentIndex === 0 ? reasons.length - itemsToShow : currentIndex - 1);
    };

    const goToNext = () => {
        setCurrentIndex(currentIndex === reasons.length - itemsToShow ? 0 : currentIndex + 1);
    };

    return (
        <section className="love-us">
            <h2 className='text-center mystic-content heading'>Why Customers Love Us</h2>
            <div className="reasons-slider">
                <div className="reasons" style={{ transform: `translateX(-${currentIndex * (100 / itemsToShow)}%)` }}>
                    {reasons.map((reason, index) => (
                        <div key={index} className="reason">
                            <img src={reason.image} alt={reason.title} />
                            <h4>{reason.title}</h4>
                            <p>{reason.description}</p>
                        </div>
                    ))}
                </div>
                <button className="prev-btn" onClick={goToPrev}>&#10094;</button>
                <button className="next-btn" onClick={goToNext}>&#10095;</button>
            </div>
        </section>
    );
};

export default LoveUs;
