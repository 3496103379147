import React from 'react';
import './ServiceList.css';

const services = [
    { title: 'Exterior Car Wash', description: 'Thorough cleaning of your car’s exterior, leaving it spotless and shiny.', imgSrc: './servicelist/exterior.jpeg' },
    { title: 'Interior Cleaning', description: 'Comprehensive interior cleaning, vacuuming, and dusting to keep your car’s cabin fresh and hygienic.', imgSrc: './servicelist/interior.jpeg' },
    { title: 'Engine Detailing', description: 'Detailed cleaning of your car’s engine to maintain performance and appearance.', imgSrc: './servicelist/engine.jpeg' },
    { title: 'Polishing and Waxing', description: 'Professional polishing and waxing to protect and enhance your car’s paintwork.', imgSrc: './servicelist/polish.jpeg' },
    { title: 'Headlight Restoration', description: 'Restoration of cloudy or yellowed headlights to improve visibility and aesthetics.', imgSrc: './servicelist/headlight.jpeg' },
    { title: 'Tire and Wheel Cleaning', description: 'Detailed cleaning of your car’s tires and wheels to remove dirt and brake dust.', imgSrc: './servicelist/wheel.jpeg' },
    // { title: 'Undercarriage Cleaning', description: 'Cleaning of your car’s undercarriage to remove dirt, salt, and other debris.', imgSrc: './images/our.jpg' },
    { title: 'Waterless Car Wash', description: 'Eco-friendly waterless car wash solution that cleans and protects your car’s exterior.', imgSrc: './servicelist/waterless.jpeg' },
    { title: 'Express Car Wash', description: 'Quick and efficient car wash services for those on the go.', imgSrc: './servicelist/express.jpeg' },
    { title: 'Car Detailing', description: 'Comprehensive detailing services to restore your car’s appearance inside and out.', imgSrc: './servicelist/detailing.jpeg' }
];

const ServiceList = () => {
    return (
        <section className="service-list" style={{borderTop:"5px solid #0FA4AF"}}>
            <h2 className='text-center mystic-content heading'>OUR SERVICE LIST</h2>
            <h3>Providing All Types of Car Maintenance Services</h3>
            <div className="services">
                {services.map((service, index) => (
                    <div key={index} className="service">
                        <img src={service.imgSrc} alt={service.title} className="service-img" />
                        <div className="service-info">
                            <h4>{service.title}</h4>
                            <p>{service.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default ServiceList;
