import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const navigateTo = (path) => () => {
    navigate(path);
    setIsMenuOpen(false); // Close menu after navigation
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScrollTo = (id) => () => {
    if (location.pathname !== '/') {
      navigate('/');
      // Add an event listener to scroll after the navigation to home completes
      setTimeout(() => {
        scrollToSection(id);
      }, 100); // Delay to ensure the page is fully rendered
    } else {
      scrollToSection(id);
    }
    setIsMenuOpen(false); // Close menu after scrolling
  };

  return (
    <HeaderContainer>
      <Logo onClick={navigateTo('/')}>Kleen On Veel</Logo>
      <MenuToggle onClick={toggleMenu}>☰</MenuToggle>
      <Nav isMenuOpen={isMenuOpen}>
        <NavItem onClick={navigateTo('/')}>Home</NavItem>
        <NavItem onClick={handleScrollTo('ServiceList')}>Services</NavItem>
        <NavItem onClick={navigateTo('/about-us')}>About Us</NavItem>
        <NavItem onClick={handleScrollTo('contact')}>Contact Us</NavItem>
        <NavItem onClick={handleScrollTo('book')}>Book Us</NavItem>
      </Nav>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  background-color: #0FA4AF;
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 60px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Logo = styled.h1`
  font-size: 24px;
  color: #2a2d34;
  text-decoration: underline;
  font-family: 'sans-serif';
  cursor: pointer;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const MenuToggle = styled.div`
  display: none;
  font-size: 24px;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 20px;
  margin-right: 10px;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #0FA4AF;
    width: 80%;
    color: white;
    font-family: "Arial Black", Sans-serif;
    font-weight: 900;
    padding: 10px;
    display: ${({ isMenuOpen }) => (isMenuOpen ? 'flex' : 'none')};
  }
`;

const NavItem = styled.div`
  font-family: 'sans-serif';
  color: #2a2d34;
  font-size: 20px;
  margin-right: 30px;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: #f39c12;
  }

  @media (max-width: 768px) {
    margin: 10px 0;
    font-size: 18px;
  }
`;

export default Header;
