import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import Car from './Car';
import Checkout from './Checkout';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import styled from 'styled-components';

const CarService = () => {
  const [selectedServices, setSelectedServices] = useState([]);
  const [error, setError] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const location = useLocation();
  const navigate = useNavigate();

  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  const selectedType = getQueryParam('type');
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedServices', JSON.stringify(selectedServices));
  }, [selectedServices]);

  const services = {
    hatchback: [
      {
        id: 1,
        name: 'Foam Wash',
        price: 25,
        image: './servicelist/exterior.jpeg',
        services: [
          "Foam exterior wash",
          "Interior vacuum clean",
          "Mats cleaning & glass cleaning",
          "Engine & underbody cleaning"
        ],
      },
      {
        id: 2,
        name: 'Dry Wash',
        price: 20,
        image: '/images/professional.jpg',
        services: ['Waterless cleaning', 'Wax polish', 'Interior vacuuming'],
      },
      {
        id: 3,
        name: 'Polishing',
        price: 30,
        image: '/images/professional.jpg',
        services: ['Exterior polishing', 'Scratch removal', 'Gloss finish'],
      },
      {
        id: 4,
        name: 'Deep Cleaning',
        price: 20,
        image: '/images/professional.jpg',
        services: ['Waterless cleaning', 'Wax polish', 'Interior vacuuming'],
      },
    ],
    suv: [
      {
        id: 1,
        name: 'Foam Wash',
        price: 25,
        image: '/images/professional.jpg',
        services: [
          "Foam exterior wash",
          "Interior vacuum clean",
          "Mats cleaning & glass cleaning",
          "Engine & underbody cleaning"
        ],
      },
      {
        id: 2,
        name: 'Dry Wash',
        price: 20,
        image: '/images/professional.jpg',
        services: ['Waterless cleaning', 'Wax polish', 'Interior vacuuming'],
      },
      {
        id: 3,
        name: 'Polishing',
        price: 30,
        image: '/images/professional.jpg',
        services: ['Exterior polishing', 'Scratch removal', 'Gloss finish'],
      },
      {
        id: 4,
        name: 'Deep Cleaning',
        price: 20,
        image: '/images/professional.jpg',
        services: ['Waterless cleaning', 'Wax polish', 'Interior vacuuming'],
      },
    ],
    sedan: [
      {
        id: 1,
        name: 'Foam Wash',
        price: 25,
        image: '/images/professional.jpg',
        services: [
          "Foam exterior wash",
          "Interior vacuum clean",
          "Mats cleaning & glass cleaning",
          "Engine & underbody cleaning"
        ],
      },
      {
        id: 2,
        name: 'Dry Wash',
        price: 20,
        image: '/images/professional.jpg',
        services: ['Waterless cleaning', 'Wax polish', 'Interior vacuuming'],
      },
      {
        id: 3,
        name: 'Polishing',
        price: 30,
        image: '/images/professional.jpg',
        services: ['Exterior polishing', 'Scratch removal', 'Gloss finish'],
      },
    ],
    bike: [
      {
        id: 1,
        name: 'Bike Wash',
        price: 25,
        image: '/images/professional.jpg',
        services: [
          "Foam exterior wash",
          "Interior vacuum clean",
          "Mats cleaning & glass cleaning",
          "Engine & underbody cleaning"
        ],
      },
      {
        id: 2,
        name: 'Bike Dry Wash',
        price: 20,
        image: '/images/professional.jpg',
        services: ['Waterless cleaning', 'Wax polish', 'Interior vacuuming'],
      },
      {
        id: 3,
        name: 'Bike Polishing',
        price: 30,
        image: '/images/professional.jpg',
        services: ['Exterior polishing', 'Scratch removal', 'Gloss finish'],
      },
      {
        id: 4,
        name: 'Bike Deep Cleaning',
        price: 20,
        image: '/images/professional.jpg',
        services: ['Waterless cleaning', 'Wax polish', 'Interior vacuuming'],
      },
    ],
    scooter: [
      {
        id: 1,
        name: 'Scooter Wash',
        price: 25,
        image: '/images/professional.jpg',
        services: [
          "Foam exterior wash",
          "Interior vacuum clean",
          "Mats cleaning & glass cleaning",
          "Engine & underbody cleaning"
        ],
      },
      {
        id: 2,
        name: 'Scooter Dry Wash',
        price: 20,
        image: '/images/professional.jpg',
        services: ['Waterless cleaning', 'Wax polish', 'Interior vacuuming'],
      },
      {
        id: 3,
        name: 'Scooter Polishing',
        price: 30,
        image: '/images/professional.jpg',
        services: ['Exterior polishing', 'Scratch removal', 'Gloss finish'],
      },
      {
        id: 4,
        name: 'Scooter Deep Cleaning',
        price: 20,
        image: '/images/professional.jpg',
        services: ['Waterless cleaning', 'Wax polish', 'Interior vacuuming'],
      },
    ],
  };

  const handleSelect = (service) => {
    if (selectedServices.some(s => s.id === service.id)) {
      setSelectedServices(selectedServices.filter(s => s.id !== service.id));
    } else {
      setSelectedServices([...selectedServices, service]);
    }
  };

  const handleBooking = () => {
    if (selectedServices.length === 0) {
      setError('Please select at least one service before proceeding.');
      return;
    }
    localStorage.setItem('selectedServices', JSON.stringify(selectedServices));
    navigate('/booking');
  };

  
  
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    touchThreshold: 15,
    NextArrow:null, // Use the custom Next Arrow
  };

  return (
    <HiddenNextArrow>
    <div style={isMobile?{marginTop:"10px"}:{marginTop:"100px"}}>  
      {isMobile ? (
        <Slider {...sliderSettings}>
          {services[selectedType]?.map((service) => (
            <Car key={service.id} service={service} onSelect={handleSelect} />
          ))}
        </Slider>
      ) : (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px' }}>
          {services[selectedType]?.map((service) => (
            <Car key={service.id} service={service} onSelect={handleSelect} />
          ))}
        </div>
      )}
      {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
      <Checkout selectedServices={selectedServices} onBooking={handleBooking} />
    </div>
    </HiddenNextArrow>
  );
};

// Styled component for custom Next Arrow

const HiddenNextArrow = styled.div`
.slick-next {
display: none !important;
}
`;

export default CarService;
