import React from "react";

import './timer.css'

export default function MyTimer() {

      return (
        <div className="timer-main" >
          <div className="timer-content" style={{background:"#2a2d34"}}>
            <p>
              Get your CAR CLEANING Journey <span>Start!!</span>
            </p>
          </div>
          <div className="timer-inner">
            
              <img src="./images/carousel/frog.png" alt="" style={{width:"20%"}}/>
              
            
            <a type="submit" href="#book" className="timer-anchor" style={{width:"50%", marginLeft:""}} >
            BOOK US
          </a>
          
            
              <img src="./images/carousel/frog.png" alt="" style={{width:"20%", transform: 'scaleX(-1)', marginLeft:""}}/>
              
            
            
          </div>
        </div>
      );
    
  };

